<template>
  <div class="container-center-horizontal">
    <div class="android screen">
      <div class="overlap-group3">
        <div class="overlap-group">
          <div class="overlap-group">
            <div class="background"></div>
            <div class="graphic-elements">
              <img class="rectangle-10" src="@/assets/rectangle-26@1x.png" />
              <div class="text-16 raleway-black-selago-40px">
                <i18n path="global.title" />
              </div>
            </div>
            <div class="your-image-here border-4px-perano custom-radius"></div>
            <div class="text">
              <div class="text-18 cormorantgaramond-normal-selago-72px">
                <i18n path="result.download" />
              </div>
              <div class="text-17 raleway-light-eerie-black-36px">
                <a :href="passUrl">
                  <div style="display: flex;">
                    <DownloadIcon :size="60" />
                    <div
                      v-html="$t('result.android')"
                      style="font-family: monospace;border-bottom1: 2px solid blue;"
                    />
                  </div>
                </a>
              </div>
            </div>
            <div class="text-19 raleway-normal-dove-gray-36px">
              <CertInfo />
            </div>
            <div class="text-20 raleway-normal-torea-bay-36px">
              <i18n :path="'result.type_'+$store.getters['passdata'].type" />
            </div>
          </div>
          <a :href="importInstallWallet">
            <div>
              <img src="@/assets/download_qr3.png" alt class="download-button-1 custom-button" />
            </div>
          </a>
          <img
            src="@/assets/download_plastic3.png"
            class="download-button-2 custom-button"
            @click="d_plastic=true"
          />
        </div>
      </div>
    </div>
    <Footer />
    <Plastic v-model="d_plastic" />
    <ErrorDialog
      v-model="d_error"
      @close="$router.push('/preview')"
      :errorText="errorText"
      :errorCode="errorCode"
    />
  </div>
</template>

<script>
import CertInfo from "@/components/CertInfo.vue";
import Plastic from "@/components/Plastic.vue";
import Footer from "@/components/Footer.vue";
import DownloadIcon from "vue-material-design-icons/Download.vue";

export default {
  name: "ResultDroid",
  components: { CertInfo, Plastic, Footer, DownloadIcon },
  data() {
    return {
      d_plastic: false,
      d_error: false,
      errorText: null,
      errorCode: null,
      // apps: [
      //   {
      //     href: this.importInstallWallet1,
      //     href1:
      //       "https://play.google.com/store/apps/details?id=com.walletunion.wallet",
      //     img: require("@/assets/walletunion@1x.png"),
      //     class: "walletunion",
      //   },
      //   {
      //     href: "https://play.google.com/store/apps/details?id=com.attidomobile.passwallet",
      //     img: require("@/assets/passwallet@1x.png"),
      //     class: "pass",
      //   },
      //   {
      //     href: "https://play.google.com/store/apps/details?id=com.passesalliance.wallet",
      //     img: require("@/assets/pass2u@1x.png"),
      //     class: "pass",
      //   },
      // ],
    };
  },
  mounted() {
    if (!this.$store.getters["passdata"].file) {
      this.errorText = this.$t("error.api");
      this.errorCode = "File not found";
      this.d_error = true;
    }
  },
  computed: {
    importInstallWallet() {
      var uri = encodeURIComponent(this.passUrl);
      var fallback =
        "https://play.google.com/store/apps/details?id=com.walletunion.wallet&referrer=" +
        uri;
      return (
        "intent://import/" +
        uri +
        "#Intent;scheme=walletunion;package=com.walletunion.wallet;" +
        "S.browser_fallback_url=" +
        fallback +
        ";end"
      );
    },
    passUrl() {
      return this.$api.link("/pkpass/" + this.$store.getters["passdata"].file);
    },
  },
};
</script>

<style scoped>
/* screen - android */
.android .download-button-1 {
  width: 330px;
  height: 445px;
  left: 180px;
  position: absolute;
  top: 915px;
  cursor: pointer;
  border-radius: 10% 0 0 10%;
  background: linear-gradient(0deg, #8b9bcc 0%, #dbe2f7 100%);
}
.android .download-button-2 {
  width: 330px;
  height: 445px;
  left: 567px;
  position: absolute;
  top: 915px;
  cursor: pointer;
  border-radius: 0 10% 10% 0;
  background: linear-gradient(0deg, #8b9bcc 0%, #dbe2f7 100%);
}
.android .apple-walletbadge {
  height: 265px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 200px;
  width: 357px;
}
.android .overlap-group1 {
  height: 465px;
  position: relative;
  width: 357px;
}
.android .text-13 {
  -webkit-text-stroke: 2px var(--camouflage);
  color: var(--gargoyle-gas);
  font-family: var(--font-family-fontawesome);
  font-size: 200px;
  left: 72px;
  letter-spacing: 0;
  line-height: 218.1px;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-shadow: -10px 10px 6px #00000029;
  top: 0;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.android {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
  width: 1080px;
}

.android .overlap-group3 {
  height: 1920px;
  position: relative;
  width: 1082px;
}

.android .overlap-group {
  height: 2347px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1081px;
}

.android .background {
  background-color: var(--link-water);
  height: 2347px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.android .graphic-elements {
  align-items: flex-start;
  background-color: var(--torea-bay-2);
  display: flex;
  height: 1323px;
  left: 1px;
  min-width: 1080px;
  padding: 66px 18px;
  position: absolute;
  top: 0;
}

.android .rectangle-10 {
  height: 144px;
  object-fit: cover;
  width: 144px;
}

.android .text-16 {
  letter-spacing: 12px;
  line-height: 64px;
  margin-left: 14px;
  margin-top: 93.67px;
  min-height: 49px;
  min-width: 718px;
  text-align: center;
  white-space: nowrap;
}

.android .your-image-here {
  background-color: var(--white);
  height: 984px;
  left: 113px;
  position: absolute;
  top: 446px;
  width: 856px;
}

.android .text {
  color: black;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 127px;
  min-height: 1638px;
  padding: 8px 0;
  position: absolute;
  top: 303px;
  width: 832px;
}

.android .text-18 {
  letter-spacing: 0.36px;
  line-height: 64px;
  margin-top: 4px;
  min-height: 90px;
  min-width: 832px;
  text-align: center;
  white-space: nowrap;
}

.android .text-17 {
  letter-spacing: 2.7px;
  line-height: 56px;
  margin-top: 1092px;
  min-height: 436px; 
  text-align: center;
  width: 830px;
}

.android .text-19 {
  height: 261px;
  left: 187px;
  letter-spacing: 2.7px;
  line-height: 70px;
  position: absolute;
  top: 565px;
  width: 710px;
}

.android .text-20 {
  -webkit-text-stroke: 2px var(--camouflage);
  left: 204px;
  letter-spacing: 2.7px;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 483px;
  white-space: nowrap;
  width: 678px;
}

.android .path-18 {
  height: 112px;
  left: 373px;
  object-fit: cover;
  position: absolute;
  top: 953px;
  width: 112px;
}

.android .path-19 {
  height: 112px;
  left: 599px;
  object-fit: cover;
  position: absolute;
  top: 953px;
  width: 112px;
}

.android .path-20 {
  height: 112px;
  left: 373px;
  object-fit: cover;
  position: absolute;
  top: 1180px;
  width: 112px;
}

.android .rectangle-31 {
  background-color: var(--torea-bay);
  height: 528px;
  left: 277px;
  position: absolute;
  top: 860px;
  width: 528px;
}

.android .text-21 {
  -webkit-text-stroke: 2px var(--camouflage);
  left: 380px;
  letter-spacing: 0;
  line-height: 218.1px;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-shadow: -10px 10px 6px #00000029;
  top: 1015px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.android .text-21:hover {
  transform: scale(1.1);
}

.android .group-89 {
  align-items: flex-start;
  display: flex;
  height: 180px;
  left: 134px;
  min-width: 812px;
  position: absolute;
  top: 1991px;
}

.android .walletunion {
  height: 180px;
  object-fit: cover;
  pointer-events: auto;
  transition: all 0.2s ease;
  width: 180px;
}

.android .walletunion:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 12px 5px -8px rgba(0, 0, 0, 0.2);
}

.android .pass {
  height: 180px;
  margin-left: 136px;
  object-fit: cover;
  pointer-events: auto;
  transition: all 0.2s ease;
  width: 180px;
}

.android .pass:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 12px 5px -8px rgba(0, 0, 0, 0.2);
}

.android .component-1-12 {
  align-items: flex-start;
  display: flex;
  height: 118px;
  left: 0;
  min-width: 1080px;
  position: absolute;
  top: 2229px;
}

.android .overlap-group2 {
  height: 118px;
  position: relative;
  width: 1080px;
}

.android .rectangle-30 {
  background-color: var(--link-water);
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.android .text-22 {
  left: 184px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.android .text-23 {
  left: 609px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.android .copyright {
  left: 447px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.android .line-1 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1080px;
}
</style>